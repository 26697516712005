import React, { useContext } from 'react';
import Header from './Header';
import HeaderMenu from '../features/builder/components/HeaderMenu';
import { Menu } from '../features/builder/components/Menu';
import { StateContext } from '../App';
import { orderBy } from 'lodash';

const Template = ({ children, onChangeEnvironment, context }) => {
  const state = useContext(StateContext);
  const currentState = state.history[state.history.length - 1];

  return (
    <div className='builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm'>
      {/*HEADER*/}
      <Header renderMenu={() => <HeaderMenu onChangeEnvironment={(websiteId) => onChangeEnvironment(websiteId)} />} onChangeEnvironment={(websiteId) => onChangeEnvironment(websiteId)} />

      <div className='builder-relative builder-flex builder-flex-1 ' style={{ minHeight: 'calc(100% - 48px)' }}>
        <div className=' builder-flex builder-flex-1 builder-h-full ' style={{ backgroundColor: '#dee2ec' }}>
          <Menu context={context} customBlocks={orderBy(currentState.customBlocks, 'description')} />

          <div className='builder-flex builder-w-full builder-flex-1 builder-h-full builder-overflow-x-hidden'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Template;
