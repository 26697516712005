import React, { forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const CustomInput = forwardRef(({ value, onClick, className, onChange }, ref) => (
  <div className={`builder-border builder-px-3 builder-py-1.5 builder-shadow-sm builder-rounded-md builder-cursor-pointer ${className}`} onClick={onClick} ref={ref}>
    <i aria-hidden='true' className='fal fa-calendar-alt builder-mr-2' />
    <input value={value} type='text' onChange={onChange} className='focus:builder-outline-none builder-cursor-pointer builder-text-gray-700' />
  </div>
));

const DatePickerField = ({ selected, onChange, classNames, dateFormat, minDate }) => {
  return <DatePicker selected={selected} onChange={onChange} className={classNames} customInput={<CustomInput />} dateFormat={dateFormat} minDate={minDate} />;
};

export default DatePickerField;
