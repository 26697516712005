import React, { useEffect, useState, useReducer } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { routes } from '../../../data/constants';
import { initialState, reducer } from '../reducer';
import { campaignInitializeHandler, campaignSetHandler, campaignSetNameHandler, campaignSetStartDateHandler, campaignSetStopDateHandler, campaignUndoChangesHandler, campaignSetLoadingHandler, campaignResetLoadingHandler } from '../actions';
import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';
import DatePickerField from '../../../../components/common/DatePickerField';
import TextInput from '../../../builder/editors/components/TextInput';
import moment from 'moment';
import LoadingSkeleton from './LoadingSkeleton';
import { head } from 'lodash';

export const CampaignsQuery = `query Campaigns($data: CampaignSearchInputType!) {    
    campaigns(data: $data) {
      id
      name
      start
      stop
      isDraft
      disabled
    }   
  }`;

export const CampaignSaveMutation = `mutation CampaignUpdate($data: CampaignUpdateInputType!) {
    campaignUpdate(data: $data) {
        ok
    }
  }`;

const Detail = ({ context }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const [formErrors, setFormErrors] = useState({});
  const { id: idFromUrl } = useParams();

  const [campaignId] = useState(idFromUrl);

  const validateForm = () => {
    let isValid = true;
    let errors = {};
    setFormErrors({});

    if (state.campaign.name === '' || state.campaign.name.length < 4) {
      errors['campaignName'] = 'The campaign name needs to be at least 4 chars long.';
      isValid = false;
    }

    if (new Date(state.campaign.startDate) > new Date(state.campaign.stopDate)) {
      errors['date'] = 'The stop date needs to be greater than start date.';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  // Fetch campaigns & set current campaign to state
  //
  const fetchCampaign = (id) => {
    campaignSetLoadingHandler(dispatch);

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: CampaignsQuery, variables: { data: { id: id } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        // Set or init campaign to state
        //
        if (result.data?.campaigns && result.data?.campaigns.length > 0) {
          const currentCampaign = result.data?.campaigns[0];
          if (currentCampaign) {
            campaignSetHandler(dispatch, currentCampaign);
          } else {
            campaignInitializeHandler(dispatch, id);
          }
        } else {
          campaignInitializeHandler(dispatch, id);
        }

        if (result?.errors) {
          const resultError = head(result?.errors);
          cogoToast.error(resultError.message);
        }

        campaignResetLoadingHandler(dispatch);
      })
      .catch((err) => {
        cogoToast.error(err);
        campaignResetLoadingHandler(dispatch);
      });
  };

  // Handle save
  const handleSave = (campaign) => {
    if (validateForm()) {
      const variables = {
        data: {
          id: campaign.id,
          name: campaign.name,
          start: moment(campaign.startDate).format('YYYY-MM-DD'),
          stop: moment(campaign.stopDate).format('YYYY-MM-DD'),
          //disabled: campaign.disabled,
          isDraft: campaign.isDraft
        }
      };

      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: CampaignSaveMutation, variables: variables })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.campaignUpdate?.ok) {
            cogoToast.success('Campaign Saved');
          } else {
            const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
            cogoToast.error(error);
          }
        })
        .catch((err) => {
          cogoToast.error(err);
        });
    }
  };

  useEffect(() => {
    fetchCampaign(campaignId);
  }, []);

  if (state.campaign === undefined || state.campaignLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <React.Fragment>
      <div className='builder-flex builder-flex-col builder-flex-1 builder-p-5'>
        <div className='builder-flex builder-flex-row builder-items-center builder-justify-between builder-mb-5'>
          <div className='builder-flex builder-flex-row builder-items-center'>
            <Link to={`${routes.CAMPAIGNS}`} className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100 builder-mr-4'>
              <i className='fa fa-chevron-left builder-mr-2' />
              Overview
            </Link>
            <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Campaign: {state.campaign?.name}</div>
          </div>
          <div className='flex'>
            {state.history === undefined && (
              <ButtonWithIndicator
                loading={false}
                onClick={() => history.push(`${routes.CAMPAIGNS}`)}
                icon='fal fa-times'
                text='Cancel'
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-blue-500'
                className='builder-ml-0 builder-mr-0'
              />
            )}
            {state.history && (
              <ButtonWithIndicator
                loading={false}
                disabled={state.history.length === 1}
                onClick={() => campaignUndoChangesHandler(dispatch)}
                icon='fal fa-undo'
                text='Undo changes'
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-blue-500'
                className='builder-ml-0 builder-mr-0'
              />
            )}

            <ButtonWithIndicator
              loading={false}
              onClick={() => handleSave(state.campaign)}
              icon='fal fa-save'
              text='Save'
              colorClass='builder-bg-primary builder-text-white'
              borderClass='builder-border builder-border-bg-blue-500'
              className='builder-ml-0 builder-mr-0'
            />
          </div>
        </div>
        <div className='builder-flex builder-p-5 builder-w-full builder-bg-gray-50'>
          <div className='builder-w-full builder-mr-5'>
            <div className='builder-flex builder-flex-col builder-mb-5'>
              <div className='builder-flex builder-flex-col builder-mb-5'>
                <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Name</div>
                <TextInput
                  placeHolder='Add campaign name'
                  className={`builder-flex builder-flex-1 builder-border builder-w-full builder-rounded-md ${formErrors['campaignName'] ? 'builder-border-red-500' : 'builder-border-gray-300'}`}
                  value={state.campaign?.name}
                  onChanged={(value) => {
                    campaignSetNameHandler(dispatch, value);
                  }}
                />
                {formErrors['campaignName'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['campaignName']}</span>}
              </div>
              <div className='builder-flex-col builder-mb-5'>
                <div className='builder-flex builder-space-x-10'>
                  <div className=' '>
                    <div className='builder-text-black builder-font-semibold builder-mb-2.5'>Start date</div>
                    <DatePickerField
                      selected={new Date(state.campaign?.startDate)}
                      onChange={(date) => {
                        campaignSetStartDateHandler(dispatch, date);
                      }}
                      className={`${formErrors['date'] ? 'builder-border-red-300' : 'builder-border-gray-300'}`}
                      dateFormat='dd/MM/yyyy'
                      minDate={new Date()}
                    />
                  </div>

                  <div className=''>
                    <div className='builder-text-black builder-font-semibold builder-mb-2.5'>Stop date</div>
                    <DatePickerField
                      selected={new Date(state.campaign?.stopDate)}
                      onChange={(date) => {
                        campaignSetStopDateHandler(dispatch, date);
                      }}
                      className={`${formErrors['date'] ? 'builder-border-red-300' : 'builder-border-gray-300'}`}
                      dateFormat='dd/MM/yyyy'
                      minDate={new Date()}
                    />
                  </div>
                </div>
                {formErrors['date'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['date']}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Detail;
